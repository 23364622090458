



















import KHamburgerMenu from "@/@core/components/navigation/KHamburgerMenu.vue"
import KCard from "@/@core/components/other/KCard.vue"
import KWrapper from "@/@core/components/other/KWrapper.vue"
import KPage from "@/@core/components/page/KPage.vue"
import KPageBody from "@/@core/components/page/KPageBody.vue"
import KPageHeader from "@/@core/components/page/KPageHeader.vue"
import KText from "@/@core/components/typography/KText.vue"
import { isMobile } from "@/@core/helpers/useBreakpoint"
import { defineComponent } from "@vue/composition-api"
import MapViewer from "../../ui/map-viewer.vue"

export default defineComponent({
  components: { KPage, KPageHeader, KPageBody, KWrapper, KHamburgerMenu, KText, KCard, MapViewer },
  name: "MapViewIndex",
  setup() {
    //

    return {
      isMobile
    }
  }
})
