


























































































































































import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import 'leaflet/dist/leaflet.css';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LCircle
} from 'vue2-leaflet';

// @ts-ignore
import L, { Icon, latLng } from 'leaflet';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useIndustryStore } from '@/modules/industry/services/store';
import DistrictFilter from '@/modules/industry/ui/filters/district-filter.vue';
import VillageFilter from '@/modules/industry/ui/filters/village-filter.vue';
import ScaleFilter from '@/modules/industry/ui/filters/scale-filter.vue';
import { useAuthStore } from '@/modules/auth/services/store';
import StatisticCard from '@/modules/dashboard/ui/StatisticCard.vue';
import { useMetaStore } from '@/modules/meta/services/store';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const greenIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const violetIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const blackIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const goldIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const greyIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const redIcon = new L.Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export default defineComponent({
  name: 'MapView',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    KDialog,
    LTooltip,
    LCircle,
    DistrictFilter,
    VillageFilter,
    ScaleFilter,
    StatisticCard,
    KTableSearch
  },
  props: {
    forLanding: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { attrs, emit }) {
    const defaultGeolocation = useMetaStore().defaultGeolocation;
    const authStore = useAuthStore();
    const isEmployee = computed(() => authStore.role == 'EMPLOYEE');
    const industries = ref<any[]>([]);
    const isLoading = ref(true);
    const showMap = ref(true);
    const mapAccuration = ref(50);
    const showLocationPermissionRequest = ref(false);
    const isTryGetPosition = ref(false);
    const mapCurrentPoint: Ref<any> = ref(null);
    const mapData = ref({
      zoom: 12.9,
      center: defaultGeolocation,
      url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      options: {
        zoomSnap: 0.5
      }
    });

    const zoomUpdate = (zoom: number) => (mapData.value.zoom = zoom);
    const centerUpdate = (center: any) =>
      (mapData.value.center = center);

    const getCurrentPosition = async () => {
      let options = {
        enableHighAccuracy: true,
        timeout: 8000,
        maximumAge: 1
      };

      try {
        const { coords } = await new Promise((resolve, reject) => {
          navigator.geolocation.watchPosition(
            resolve,
            reject,
            options
          );
        });

        return {
          result: coords,
          error: null
        };
      } catch (error) {
        return {
          coords: null,
          error
        };
      }
    };

    const locationPermissionRequest = async () => {
      const permissionStatus = await navigator?.permissions?.query({
        name: 'geolocation'
      });
      const hasPermission = permissionStatus?.state == 'granted';

      if (!hasPermission) showLocationPermissionRequest.value = true;
      else requestLocation(undefined);
    };

    const requestLocation = async (callback: any): Promise<any> => {
      showLocationPermissionRequest.value = false;
      isTryGetPosition.value = true;

      const { result, error }: any = await getCurrentPosition();

      if (error) {
        alert(
          'Ada kesalahan, silahkan muat ulang halaman\n' +
            error.message
        );
        isTryGetPosition.value = false;
        mapData.value.center = defaultGeolocation;

        return;
      }

      const { latitude, longitude, accuracy } = result;
      const treshold = 100;
      const isAccurate = accuracy && accuracy < treshold;
      mapAccuration.value = accuracy;

      mapCurrentPoint.value = [latitude, longitude];
      mapData.value.center = [latitude, longitude];

      isTryGetPosition.value = false;
    };

    const saveCurrentPosition = () => {
      const [latitude, longitude] = mapCurrentPoint.value;
      const geolocation = {
        latitude,
        longitude
      };

      if (!geolocation) return;

      mapCurrentPoint.value = null;
      // mapAccuration.value = 0
    };

    const filter = ref({
      district: 'Semua',
      village: 'Semua',
      isVerified: 'Semua',
      scale: 'Semua'
    });
    const search = ref('');
    const searchKey = ref(0);

    const getManyIndustryGeolocation = async () => {
      industries.value = [];
      isLoading.value = true;
      searchKey.value++;

      const options: any = {};
      const response =
        await useIndustryStore().getManyIndustryGeolocation({
          filter: unref(filter),
          options: unref(options),
          search: unref(search)
        });

      if (response.industries) industries.value = response.industries;

      isLoading.value = false;
      setTimeout(() => searchKey.value++, 400);
    };

    const toGeolocation = (geolocation: any) => {
      if (!geolocation?.latitude || !geolocation?.longitude)
        return null;
      if (String(geolocation?.latitude)?.length < 3) return null;

      const latitude = geolocation.latitude;
      const longitude = geolocation.longitude;
      const result = [Number(latitude), Number(longitude)];

      if (Number.isNaN(latitude))
        console.log('geolocation:', geolocation);

      return result;
    };

    const usedIcon = (scale: string) => {
      let icon = blackIcon;

      if (scale == 'mikro') icon = violetIcon;
      if (scale == 'kecil') icon = greenIcon;
      if (scale == 'menengah') icon = goldIcon;
      if (scale == 'besar') icon = greyIcon;

      return icon;
    };

    const statistics = computed(() => {
      const data = unref(industries);
      const microIndustries = data.filter((d) => d.scale == 'mikro');
      const smallIndustries = data.filter((d) => d.scale == 'kecil');
      const mediumIndustries = data.filter(
        (d) => d.scale == 'menengah'
      );
      const largeIndustries = data.filter((d) => d.scale == 'besar');

      const result = [
        {
          title: data.length ?? 0,
          text: 'Total Industri',
          icon: 'mdi-map-marker',
          iconColor: 'black',
          // attributes: {
          //     cols: 12,
          //     sm: 12,
          //     md: 4,
          // },
          attributes: {
            cols: 12,
            sm: 6,
            md: 6
          }
        },
        // {
        //     title: microIndustries.length ?? 0,
        //     text: 'Industri Mikro',
        //     icon: 'mdi-map-marker',
        //     iconColor: '#9C2BCB',
        //     attributes: {
        //         cols: 12,
        //         sm: 6,
        //         md: 6,
        //     }
        // },
        {
          title: smallIndustries.length ?? 0,
          text: 'Industri Kecil',
          icon: 'mdi-map-marker',
          iconColor: '#9C2BCB',
          // iconColor: '#2AAD27',
          attributes: {
            cols: 12,
            sm: 6,
            md: 6
          }
        },
        {
          title: mediumIndustries.length ?? 0,
          text: 'Industri Menengah',
          icon: 'mdi-map-marker',
          iconColor: '#FFD326',
          attributes: {
            cols: 12,
            sm: 6,
            md: 6
            // 'offset-md': 1
          }
        },
        {
          title: largeIndustries.length ?? 0,
          text: 'Industri Besar',
          icon: 'mdi-map-marker',
          iconColor: '#7B7B7B',
          attributes: {
            cols: 12,
            sm: 6,
            md: 6
            // 'offset-md': 1
          }
        }
      ];

      return result;
    });

    const filteredIndustries = computed(() => {
      const passes = (i: any) => {
        const f = unref(filter);
        const useDistrict = f.district !== 'Semua';
        const useVillage = f.village !== 'Semua';
        const useScale = f.scale !== 'Semua';

        const passDistrict = useDistrict
          ? i.district == f.district
          : true;
        const passVillage = useVillage
          ? i.village == f.village
          : true;
        const passScale = useScale ? i.scale == f.scale : true;
        const isPasses = passDistrict && passVillage && passScale;

        if (isPasses) return i;
      };

      return industries.value.filter((i) => passes(i));
    });

    const detailIndustry = (industry: any) => {
      const protocol = window.location.protocol + '//';
      const hostname = window.location.hostname;
      const port = window.location.port;
      const route = props.forLanding
        ? '/industri/'
        : '/app/industri/';
      const url: any = `${protocol}${hostname}:${port}${route}${industry.id}`;

      window.open(url, '_blank');
    };

    watch(
      () => filter,
      async () => await getManyIndustryGeolocation(),
      { immediate: true, deep: true }
    );
    watch(
      () => search,
      async () => await getManyIndustryGeolocation(),
      { immediate: false, deep: true }
    );

    onMounted(() => {
      if (unref(isEmployee))
        filter.value.district = authStore.user?.district?.name;
      locationPermissionRequest();
    });

    return {
      mapData,
      showMap,
      zoomUpdate,
      centerUpdate,
      showLocationPermissionRequest,
      requestLocation,
      redIcon,
      greenIcon,
      mapCurrentPoint,
      locationPermissionRequest,
      isTryGetPosition,
      saveCurrentPosition,
      isMobile,
      mapAccuration,
      isLoading,
      toGeolocation,
      usedIcon,
      filteredIndustries,
      filter,
      isEmployee,
      statistics,
      detailIndustry,
      search,
      searchKey
    };
  }
});
