














import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KComboboxInput },
  name: 'IndustryIsVerifiedFilter',
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });

    const scaleList = [
      {
        text: 'Semua',
        value: 'Semua'
      },
      // {
      //   text: 'Mikro',
      //   value: 'mikro'
      // },
      {
        text: 'Kecil',
        value: 'kecil'
      },
      {
        text: 'Menengah',
        value: 'menengah'
      },
      {
        text: 'Besar',
        value: 'besar'
      }
    ];

    return {
      modelValue,
      scaleList
    };
  }
});
